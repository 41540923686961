import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { NumberKeypadComponent } from './number-keypad/number-keypad.component';

@Injectable()
export class NumberKeypadService {

  constructor(private _modalService: BsModalService) { }

  ShowNumberkeypad():Observable<number>{
    
    let NumberKeypadModal:BsModalRef;
    NumberKeypadModal=this._modalService.show(
      NumberKeypadComponent,
      {
        class: 'modal-lg',
       initialState:{
       },
      }
      );
 return  NumberKeypadModal.content.onSave
  }
}
