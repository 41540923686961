import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LayoutStoreService } from '@shared/layout/layout-store.service';
import { observable, Observable, of } from 'rxjs';

@Injectable()
export class FullScreenService {
  elem;
  public displayLayoutFooter:Observable<boolean>;
  constructor(@Inject(DOCUMENT) private document: any,private _layoutStore: LayoutStoreService,) {
    this.elem= document.documentElement;
   }

  openFullscreen() {
    this._layoutStore.setSidebarExpanded(true);
   
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }

    this.displayLayoutFooter=of(false);

  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
    this.displayLayoutFooter=of(true);
  }



}
