<div class="modal-header">
   
    <button type="button" class="close"  aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body " >

    <div class="form-group row mb-0 ">
      
      <div class="col-md-6" name="quantity" id="quantity">
        {{value.join("")}}
      </div>
      </div>

<div class="row" style="direction: ltr;">
  <span *ngFor="let number of numbers"  class="col-md-4" >
     
      <button  type="button" class=" btn btn-secondary " style="width: 100%; height: 90%;" (click)="addNumber(number)"  >
          {{number}}
      </button>
  
      <div *ngIf="number == 3 || number == 6 || number == 9">
          <br />
      </div>
  </span>
  
  <span class="col-md-4">
  <button type="button" class="btn btn-secondary " style="width: 100%; height: 90%;" (click)="removeLastNumber()" ><i class="fas fa-backspace"></i></button>
  </span>
  </div>



</div>

<div class="modal-footer " >
    <button type="button" class="btn btn-light"  (click)="bsModalRef.hide()" >{{"Cancel"|localize}}</button>
    <button type="button" class="btn btn-primary" (click)="save()">{{"Save"|localize}}</button>
  </div>


