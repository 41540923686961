import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';




@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.CategoryServiceProxy,
        ApiServiceProxies.SharedShiftServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.ShiftServiceProxy,
        ApiServiceProxies.SupervisorServiceProxy,
        ApiServiceProxies.ReservationServiceProxy,
        ApiServiceProxies.BranchServiceProxy,
        ApiServiceProxies.DeliveryCompanyServiceProxy,
        ApiServiceProxies.POS_UserSettingServiceProxy,
        ApiServiceProxies.PaymentRevenueServiceProxy,
        ApiServiceProxies.CustomerServiceProxy,
        ApiServiceProxies.PointOfSaleDefinitionServiceProxy,


        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
