import { AbpMultiTenancyService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import {
    ApplicationInfoDto,
    GetCurrentLoginInformationsOutput,
    PointOfSaleDefinitionDTO,
    SessionServiceProxy,
    TenantLoginInfoDto,
    UserLoginInfoDto
} from '@shared/service-proxies/service-proxies';
import { runInThisContext } from 'vm';

@Injectable()
export class AppSessionService {

    private _user: UserLoginInfoDto;
    private _tenant: TenantLoginInfoDto;
    private _application: ApplicationInfoDto;
    private _pointOfSaleDefinition: PointOfSaleDefinitionDTO;
    private _shiftCashierId: number;
    constructor(
        private _sessionService: SessionServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService) {
    }

    get application(): ApplicationInfoDto {
        return this._application;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get pointOfSaleDefinition(): PointOfSaleDefinitionDTO {
        return this._pointOfSaleDefinition;
    }
    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }
    get shiftCashierId(): number {
        return this.shiftCashierId ? this.shiftCashierId : null;
    }
    changePointOfSaleDefinition(pos: PointOfSaleDefinitionDTO) {
        this._pointOfSaleDefinition = pos;
    }
    changeshiftCashierId(id: number) {
        this._shiftCashierId = id;
    }


    getShownLoginName(): string {
        const userName = this._user.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
    }


    init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._sessionService.getCurrentLoginInformations().toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._tenant = result.tenant;
                this._pointOfSaleDefinition = result.pointOfSaleDefinition;
                console.log(result)
                this._shiftCashierId = result.shiftCashierId;
                if (result.shiftCashierId != 0) {

                    localStorage.setItem('ShiftCashierID', String(result.shiftCashierId));
                    localStorage.removeItem('rootCategories');
                    localStorage.removeItem('productsData');

                }
                else {
                    localStorage.removeItem('rootCategories');
                    localStorage.removeItem('productsData');
                    localStorage.removeItem('ShiftCashierID');
                }


                resolve(true);
            }, (err) => {
                reject(err);
            });
        });
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }
}
