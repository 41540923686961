import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-number-keypad',
  templateUrl: './number-keypad.component.html',
  styleUrls: ['./number-keypad.component.css']
})
export class NumberKeypadComponent  extends AppComponentBase implements OnInit {

  
  numbers:string[]=["1","2","3","4","5","6","7","8","9",".","0"];
 
  value:number[]=[];
  @Output() onSave= new EventEmitter<number>();
  constructor( injector: Injector, public bsModalRef: BsModalRef) { 
    super(injector);
  }

  ngOnInit(): void {
  }
  addNumber(number:number){
    this.value.push(number);
  }
  removeLastNumber(){
    this.value.splice(-1, 1);
  }

save()
{
  this.onSave.emit(Number(this.value.join("")));
  this.bsModalRef.hide();
 
}
}